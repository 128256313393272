import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button } from "antd";
import UserInput from "../UserInput/UserInput";
import "./Details.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseUrl } from "../../Assets/api";

const { Option } = Select;

const Details = ({
  data,
  setIsAdd,
  setData,
  permanentAddress,
  setCommunicationAddress,
}) => {
  const token = useSelector((state) => state.tokenReducer.data.token);
  const [verification, setVerification] = useState({
    generate: false,
    verified: false,
  });

  /* Timer */
  const [seconds, setSeconds] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else if (!isTimerActive || seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, seconds]);

  const handleSendOtpOnClick = async () => {
    try {
      var mobile = JSON.stringify({
        mobile: data.contact,
      });

      var config = {
        method: "POST",
        url: `${baseUrl}/tryam/user/generate-otp`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: mobile,
      };

      const response = await axios(config);
      console.log(response.data);
      setVerification({ ...verification, generate: true });
      setIsTimerActive(true);
    } catch (err) {
      alert("verification failed");
      throw err;
    }
  };

  const handleVerifyOtpOnClick = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/tryam/user/verify-otp`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: {
          otp: data.contactOtp,
        },
      });
      console.log(response.data);
      setVerification({ ...verification, verified: true });
    } catch (err) {
      alert("Wrong OTP");
      console.log("otp", err);
    }
  };

  const handleContactOtpOnChange = (e) => {
    console.log("contactOTP", e.target.value);
    setData({ ...data, contactOtp: e.target.value });
  };

  const handleContactOnChange = (e) => {
    console.log("Contact", e.target.value);
    setData({ ...data, contact: e.target.value });
  };
  const handleEmailOnChange = (e) => {
    console.log("Email", e.target.value);
    setData({ ...data, email: e.target.value });
  };

  const handleNameOnChange = (e) => {
    console.log("Name", e.target.value);
    setData({ ...data, name: e.target.value });
  };
  const handleAddressOnChange = (e) => {
    if (e === "yes") {
      setIsAdd(true);
      setCommunicationAddress(Object.assign({}, permanentAddress));
    } else {
      setIsAdd(false);
      setCommunicationAddress({
        area: "",
        landmark: "",
        pincode: "",
        state: "",
      });
    }
  };

  return (
    <>
      <UserInput
        name="fullName"
        value={data.name}
        label="Full Name"
        placeholder="Enter Full Name"
        onChange={handleNameOnChange}
      />
      <UserInput
        name="email"
        value={data.email}
        label="Email Address"
        placeholder="Enter Email Address"
        onChange={handleEmailOnChange}
      />
      <div className="contactno">
        {!verification.generate ? (
          <>
            <UserInput
              name="contact"
              value={data.contact}
              label="Contact Number"
              placeholder="Enter Contact Number"
              onChange={handleContactOnChange}
            />
            <Button
              onClick={handleSendOtpOnClick}
              className="contactno__btn"
              type="primary"
            >
              Send OTP
            </Button>
          </>
        ) : (
          <>
            <UserInput
              name="opt"
              value={data.contactOtp}
              label="Enter OTP"
              placeholder="Enter OTP"
              onChange={handleContactOtpOnChange}
            />
            {!verification.verified ? (
              <Button
                onClick={handleVerifyOtpOnClick}
                className="contactno__btn"
                type="primary"
              >
                Verify
                {seconds}
              </Button>
            ) : (
              <Button className="contactno__btn" type="primary" disabled>
                Verified
              </Button>
            )}
          </>
        )}
      </div>

      <Row gutter={{ xs: 5, sm: 5, md: 24, lg: 30 }}>
        <Col>
          <Form.Item label="Address ( is your communication address same as permanent address )"></Form.Item>
        </Col>
        <Col span={{ sm: 10, xlg: 4 }}>
          <Form.Item name="address">
            <Select onChange={handleAddressOnChange}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Details;
