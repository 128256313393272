import React, { useState, useEffect, useRef } from "react";
import * as faceapi from "face-api.js";
import Frame from "../../Assets/img/frame.png";
import axios from "axios";

import { updateFormData } from "../../redux/formReducer";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../Assets/api";

const FaceDetect = () => {
  const [initialization, setInitialization] = useState("");
  const [validDetections, setValidDetections] = useState(false);
  // const [descriptorsArray, setDescriptorsArray] = useState([]);

  const videoRef = useRef();

  const descriptorsArray = [];
  const notAvailable = [];
  let faceStream = null;

  const dispatch = useDispatch();

  const token = useSelector((state) => state.tokenReducer.data.token);

  const maxNameOccurence = (array) => {
    if (array.length == 0) return null;
    var modeMap = {};
    var maxEl = array[0],
      maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] == null) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  };

  const checkDescriptorIfExists = async (data) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/tryam/user/check-if-exists`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      });
      descriptorsArray.push(response.data.data);
    } catch (err) {
      notAvailable.push(err.response.status);
      console.log("not abva");
    }
  };

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      setInitialization("Initializing");
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]);
      setInitialization("Place your face on screen");
      startVideo();
    };
    loadModels();
  }, []);

  const startVideo = async () => {
    faceStream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = faceStream;
  };

  const handleVideoOnPlay = () => {
    const imgLinks = [];

    let detectInterval = setInterval(async () => {
      const detect = await faceapi
        .detectSingleFace(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions()
        )
        .withFaceLandmarks()
        .withFaceDescriptor();

      let nose = detect?.landmarks?.getNose()[0];
      let descriptors = { descriptors: [...detect?.descriptor] };

      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext("2d").drawImage(videoRef.current, 0, 0);
      imgLinks.push(canvas.toDataURL("image/webp"));

      checkDescriptorIfExists(descriptors);
      if (descriptorsArray.length >= 5) {
        // check for max value and dispatch
        dispatch(
          updateFormData({
            ...descriptorsArray.find(
              (el) =>
                el.name ===
                maxNameOccurence(descriptorsArray.map((el) => el.name))
            ),
            faceSnapshots: imgLinks,
          })
        );
        setInitialization("Captured and retrieved data");
        clearInterval(detectInterval);
      } else if (notAvailable.length >= 5) {
        dispatch(
          updateFormData({
            faceSnapshots: imgLinks,
          })
        );
        setInitialization("Please fill the data manually");
        clearInterval(detectInterval);
      }
      nose ? setValidDetections(true) : setValidDetections(false);
    }, 1500);
    return () => clearInterval(detectInterval);
  };
  return (
    <>
      <div className="video-container">
        <video
          // className="face"
          ref={videoRef}
          autoPlay
          muted
          onPlay={handleVideoOnPlay}
        ></video>
        <img
          style={{ background: validDetections ? "#00800057" : "#ff000057" }}
          className="faceFrame"
          src={Frame}
          alt=""
        />
      </div>
      <h4>{initialization}</h4>
    </>
  );
};

export default FaceDetect;
