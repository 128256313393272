import React, { useState } from "react";
import { baseUrl } from "../../Assets/api";
import "./UserUpload.css";
import { useSelector } from "react-redux";

const axios = require("axios");

const UserUpload = ({ label, uploadId }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [image, setImage] = useState();

  const token = useSelector((state) => state.tokenReducer.data.token);

  let formData = new FormData();

  const handleOnChange = (e) => {
    setIsSubmit(false);
    // setUploadFile(e.target.files[0].name);

    if (e.target && e.target.files[0]) {
      formData.append("image", e.target.files[0]);
    }
  };

  const handleOnClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/tryam/user/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      });
      console.log("response", response.data.data.fileName);

      let imgLink = await axios({
        method: "GET",
        url: `${baseUrl}/tryam/user/upload/signed-url?path=${response.data.data.fileName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("imgLink", imgLink.data.data);

      setIsSubmit(true);
      setImage(imgLink.data.data);
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="custom-upload">
      <label className="custom-upload-label" htmlFor={uploadId}>
        {label}
      </label>
      <input
        className="custom-upload-input"
        type="file"
        id={uploadId}
        accept="image/*"
        onChange={handleOnChange}
      />
      {/* <p>{uploadFile}</p> */}
      {isSubmit ? (
        <a className="custom-upload-view" target="_blank" href={image}>
          view
        </a>
      ) : (
        <button className="custom-upload-submit" onClick={handleOnClick}>
          Submit
        </button>
      )}
    </div>
  );
};

export default UserUpload;
