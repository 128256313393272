import { createSlice } from "@reduxjs/toolkit";

const formReducer = createSlice({
  name: "formData",
  initialState: { data: {} },
  reducers: {
    updateFormData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateFormData } = formReducer.actions;
export default formReducer.reducer;
