import React, { useState, useEffect } from "react";
import title_logo from "../Assets/img/TRYAM - V2.png";
import logo from "../Assets/img/garun_logo.png";
import Details from "../components/Details/Details";
import Address from "../components/Address/Address";
import FaceDetect from "../components/FaceDetect/FaceDetect";
import DetectHand from "../components/DetectHand/DetectHand";
import UserUpload from "../components/UserUpload/UserUpload";
import { useSelector } from "react-redux";
import { Button } from "antd";
import axios from "axios";
import { baseUrl } from "../Assets/api";

const RegistrationForm = () => {
  const token = useSelector((state) => state.tokenReducer.data.token);
  const [isAdd, setIsAdd] = useState(true);
  const [detections, setDetections] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    contact: "",
    contactOtp: "",
  });
  const [permanentAddress, setPermanentAddress] = useState({
    area: "",
    landmark: "",
    pincode: "",
    state: "",
  });
  const [communicationAddress, setCommunicationAddress] = useState({
    area: "",
    landmark: "",
    pincode: "",
    state: "",
  });
  const formData = useSelector((state) => state.formReducer.data);

  const [aadharData, setAadharData] = useState({});

  useEffect(() => {
    if (Object.keys(formData).length > 1) {
      // console.log("###", formData, Object.keys(formData).length);
      setAadharData(JSON.parse(formData.aadhaarData).data);
    }
  }, [formData]);

  useEffect(() => {
    if (Object.keys(aadharData).length) {
      if (formData.address.addressPermanent.length) {
        setDetails({
          name: aadharData.full_name,
          email: "",
          contact: "",
        });
        setCommunicationAddress({
          area: "",
          landmark: "",
          pincode: "",
          state: "",
        });
        setPermanentAddress({
          area: "",
          landmark: "",
          pincode: "",
          state: "",
        });
      } else {
        setDetails({
          name: aadharData.full_name,
          email: "",
          contact: "",
        });
        setCommunicationAddress({
          area: "",
          landmark: "",
          pincode: "",
          state: "",
        });
        setPermanentAddress({
          area: `${aadharData?.address?.house}, ${aadharData?.address?.loc}, ${aadharData?.address?.street}`,
          landmark: aadharData?.address?.landmark,
          pincode: aadharData?.zip,
          state: aadharData?.address?.state,
        });
      }
    }
  }, [aadharData]);

  const handleFormOnClick = async () => {
    let _id = formData._id;
    let userData = {
      _id,
      details,
      permanentAddress,
      communicationAddress,
    };
    console.log(userData);
    try {
      const response = await axios({
        method: "POST",
        url: `${baseUrl}/tryam/user/register`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          _id: formData._id,
          userData: userData,
        },
      });
      console.log("Form Submit", response.data.success);
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <div className="header">
        <img src={title_logo} className="title_logo" />
        <img src={logo} className="logo" />
      </div>
      <div className="subTitle">
        <h2 className="sub">individual</h2>
        <h2 className="sub">Registration form</h2>
      </div>

      <div className="update">
        <div className="update__individual">
          <Details
            data={details}
            setIsAdd={setIsAdd}
            setData={setDetails}
            permanentAddress={permanentAddress}
            setCommunicationAddress={setCommunicationAddress}
          />
          <div className="addressDetails">
            <Address
              data={permanentAddress}
              setData={setPermanentAddress}
              title="Permanent Address"
              name="permanent"
            />
            {isAdd ? (
              <></>
            ) : (
              <Address
                data={communicationAddress}
                setData={setCommunicationAddress}
                name="communication"
                title="Communication Address"
              />
            )}
          </div>
          <h3>Upload Document</h3>
          <UserUpload uploadId="aadhaarIdFront" label="Front" />
          <UserUpload uploadId="aadhaarIdBack" label="Back" />
        </div>
        <div className="update__detect">
          {detections ? (
            <DetectHand detections={detections} setDetections={setDetections} />
          ) : (
            <FaceDetect detections={detections} setDetections={setDetections} />
          )}
        </div>
      </div>
      <div className="formSubmit">
        <Button onClick={handleFormOnClick} type="primary">
          Submit
        </Button>
      </div>
    </>
  );
};

export default RegistrationForm;
