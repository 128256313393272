import React from "react";
import "./UserInput.css";

const UserInput = ({
  type = "text",
  placeholder = "",
  label = "",
  name = "",
  value = "",
  onChange,
  autoComplete = "off",
  disabled = "",
  defaultValue = "",
}) => {
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        name={name}
        className="form-control"
        placeholder={defaultValue ? defaultValue : placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={onChange}
      />
    </div>
  );
};

export default UserInput;
