import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateJwtToken } from "./redux/tokenReducer";
import Registration from "./screens/Registration";
import RegistrationForm from "./screens/RegistrationForm";
import "./App.css";

function App() {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");

  const dispatch = useDispatch();

  dispatch(updateJwtToken({ token }));

  const [flag, setFlag] = useState(false);
  return (
    <>{flag ? <RegistrationForm /> : <Registration setFlag={setFlag} />}</>
  );
}

export default App;
