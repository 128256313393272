import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./formReducer";
import tokenReducer from "./tokenReducer";

export const store = configureStore({
  reducer: {
    formReducer,
    tokenReducer,
  },
});
