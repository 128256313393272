export const baseUrl = "https://backend-gateway.garun.in";

// async function apicall({ method = "POST", url, data = null }, token) {
//   try {
//     let resp = await fetch(url, {
//       method: method,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         ...(token ? { Authorization: `Bearer ${token}` } : {}),
//       },
//       ...(data ? { body: JSON.stringify(data) } : {}),
//     });
//     if (resp.status != 200 && resp.status != 304) {
//       let data = await resp.json();
//       alert(data.message || "something went wrong");
//       throw data.message || "something went wrong.";
//     }
//     return resp.json();
//   } catch (error) {
//     throw error;
//   }
// }
