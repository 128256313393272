import React, { useState, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import * as handpose from "@tensorflow-models/handpose";
import Webcam from "react-webcam";
import { drawHand } from "../../utilities";

let image_data_url;
let cnt = 0;

function DetectHand({ detections, setDetections }) {
  const [handModelInit, setHandModelInit] = useState("Initializing");
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  //stop the setInterval
  function stopInterval(check) {
    clearInterval(check);
  }

  const runHandpose = async () => {
    const net = await handpose.load();
    setHandModelInit("Place your hand on screen");
    //  Loop and detect hands
    let check = setInterval(() => {
      if (cnt >= 10) {
        stopInterval(check);
      }
      detect(net);
    }, 500);
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      const hand = await net.estimateHands(video);

      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");
      drawHand(hand, ctx);

      image_data_url = canvasRef.current.toDataURL("image/jpeg");
      // data url of the image
      if (hand.length) {
        cnt++;
      } else cnt = 0;
    }
  };

  runHandpose();

  return (
    <div className="video-container">
      <Webcam
        ref={webcamRef}
        className="webcamHand"
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zindex: 9,
        }}
      />

      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          zindex: 9,
          width: "100%",
          height: "100%",
          transform: "scaleX(-1)",
        }}
      />
      <h4>{handModelInit}</h4>
    </div>
  );
}

export default DetectHand;
