import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Form, Select, Input } from "antd";
import "./Address.css";
import { useSelector } from "react-redux";
import UserInput from "../UserInput/UserInput";

const { Option } = Select;
const { Title, Paragraph } = Typography;
const Address = ({ name, data, setData, title }) => {
  const formData = useSelector((state) => state.formReducer.data);
  // console.log("address", data);

  const handleAreaOnChange = (e) => {
    console.log(e.target.value);
    setData({ ...data, area: e.target.value });
  };

  const handleStateOnChange = (e) => {
    console.log(e.target.value);
    setData({ ...data, state: e.target.value });
  };

  const handlePincodeOnChange = (e) => {
    console.log("pincode", e.target.value);
    setData({ ...data, pincode: e.target.value });
  };
  const handleLandmarkOnChange = (e) => {
    console.log("landmark", e.target.value);
    setData({ ...data, landmark: e.target.value });
  };
  return (
    <div className="address">
      <h3>{title}</h3>

      <div className="form-group">
        <label className="form-label" htmlFor={`${name}-address`}>
          Address
        </label>
        <textarea
          name={`${name}-address`}
          rows="6"
          className="form-control"
          placeholder="Enter Address"
          value={data?.area}
          onChange={handleAreaOnChange}
        />
      </div>

      <UserInput
        name={`${name}-landmark`}
        value={data?.landmark}
        placeholder="Enter Landmark"
        onChange={handleLandmarkOnChange}
        label="Landmark: "
      />
      <UserInput
        name={`${name}-pincode`}
        value={data?.pincode}
        placeholder="Enter Pincode"
        onChange={handlePincodeOnChange}
        label="Pincode: "
      />
      <UserInput
        name={`${name}-state`}
        value={data?.state}
        placeholder="Enter State"
        onChange={handleStateOnChange}
        label="State: "
      />
    </div>
  );
};

export default Address;
