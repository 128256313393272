import React, { useEffect, useState } from "react";
import title_logo from "../Assets/img/TRYAM - V2.png";
import logo from "../Assets/img/garun_logo.png";
import FaceDetect from "../components/FaceDetect/FaceDetect";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import { baseUrl } from "../Assets/api";
import UserInput from "../components/UserInput/UserInput";

const Registration = ({ setFlag }) => {
  const [detections, setDetections] = useState({
    face: { left: false, front: false, right: false },
    hand: false,
  });
  const [formState, setFormState] = useState({
    name: "",
    email: "",
  });

  const formData = useSelector((state) => state.formReducer.data);
  // console.log(formData);

  const token = useSelector((state) => state.tokenReducer.data.token);

  useEffect(() => {
    setFormState({
      ...formState,
      name: formData.name,
      email: "",
    });
  }, [formData]);

  const fullNameOnChange = (e) => {
    setFormState({ ...formState, name: e.target.value });
  };
  const emailOnChange = (e) => {
    setFormState({ ...formState, email: e.target.value });
  };

  const handleOnClick = async () => {
    if (formState.name.length) {
      console.log(formState);
      try {
        const response = await axios({
          method: "POST",
          url: `${baseUrl}/tryam/user/register`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            _id: formData._id,
            faceSnapshots: formData.faceSnapshots,
          },
        });

        setFlag(true);
        console.log(response.status, response.data.success);
      } catch (err) {
        throw err;
      }
    } else message.error("Fill Details");
  };

  return (
    <>
      <div className="header">
        <img src={title_logo} className="title_logo" />
        <img src={logo} className="logo" />
      </div>
      <div className="subTitle">
        <h2 className="sub">INDIVIDUAL REGISTRATION</h2>
      </div>
      <div className="register">
        <div className="register__consent">
          <div className="register__detect">
            <FaceDetect detections={detections} setDetections={setDetections} />
          </div>
          <div className="register__info">
            <span className="register__info--heading">
              Important Information
            </span>
            <p>
              It is pertinent to note, that the browser may request you to grant
              permission to access of Webcam and microphone (in case of laptop
              or desktop) or Front camera and microphone (in case of tablet or
              mobile phone).
            </p>
            <p>
              We request you to kindly grant such permissions to the browser, as
              it is necessary for functioning of this module. In other words, if
              you deny such permission request, this module will not function
              and the webpage will be redirected to the relevant section of
              FAQs, maintained by our organization.
            </p>
            <p>
              Further, we wish to mention that the browser may also, request you
              to grant permission to access location services of your device.
            </p>
            <p>
              It is an optional service, the user may choose, whether to grant
              permission or not. This service is used to assist you with certain
              basic information (for example state, city and probable area in
              your permanent or communication address) for the purpose of saving
              your precious time.
            </p>
            <p>
              Also, this permission is required for marking geo-location of your
              address in the respective maps, as an option information.
            </p>
            <div className="register__info--tnc">
              <Checkbox>
                Yes, i have read and understand the important message mentioned
                by Garun Smart,regarding permissions for proper functioning of
                this module.
              </Checkbox>
            </div>
          </div>
        </div>
        <div className="register__form">
          {/* Id */}
          <UserInput
            name="garunID"
            label="Unique Garun ID: "
            placeholder="garun id"
            value={formData._id}
            disabled
          />
          {/* Name */}
          <UserInput
            name="fullname"
            label="Full Name: "
            placeholder="Enter Full Name"
            value={formState.name}
            onChange={fullNameOnChange}
          />
          {/* email */}
          <UserInput
            name="email"
            label="E-mail Address: "
            type="email"
            placeholder="Enter E-mail Address"
            value={formState.email}
            onChange={emailOnChange}
          />
        </div>
        <div className="register__footer">
          <Button>CAPTCHA</Button>
          <Button onClick={handleOnClick}>PROCEED TO UPDATE</Button>
          <Button>REPORT DISCREPANCY AND LOGIN MANUALLY</Button>
        </div>
      </div>
    </>
  );
};

export default Registration;
